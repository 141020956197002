import React from 'react';
import useResources from '../../../hooks/useResources';
import Section from '../../../molecules/Section/Section';
import Slider from '../../../molecules/Slider/Slider';
import ResourceCard from '../../Cards/ResourcesCard';
import {
  Header,
  innerStyles,
  sectionStyles,
  sliderStyles,
  SliderWrapper,
} from '../styles/ResourcesSliderCardDeck.styled';

const ResourcesSliderCardDeck = () => {
  const { recommendedResources } = useResources();
  return (
    <Section style={sectionStyles} innerStyle={innerStyles}>
      <Header>
        <h2>Recommended</h2>
      </Header>
      <SliderWrapper>
        <Slider styles={sliderStyles} hideOffSceenSlide>
          {recommendedResources?.map(resource => (
            <ResourceCard
              excerpt={resource?.excerpt}
              image={resource?.image}
              key={resource?.id}
              link={resource?.url}
              metaLabel={resource?.metaLabel}
              postBody={resource?.postBody}
              tags={resource?.tags}
              title={resource?.name}
              wordCount={resource?.wordCount}
            />
          ))}
        </Slider>
      </SliderWrapper>
    </Section>
  );
};

export default ResourcesSliderCardDeck;
